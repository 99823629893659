import {
  validatePhoneNumber,
  validateAddressField,
  validateEmail,
  validateZipCode,
  validateExistsAndAlphabetCharacters,
  validateNameField,
  validateAlphabetCharacters,
} from '../../util/form-validation';
import { UseTranslationHook, useTranslation } from '../../hooks/useTranslation';
import { convertValidationRules } from '../../hooks/useFormState';

const { t } = useTranslation();

export type Model = {
  requestorFirstName: string;
  requestorMiddleName?: string;
  requestorLastName: string;
  requestorPhoneType: string;
  requestorPhoneValue: any;
  requestorAddress: string;
  requestorCity: string;
  requestorState: string;
  requestorZip: string;
  personalInfoReport?: boolean;
  deleteInfo?: boolean;
  marketingCommunication?: boolean;
  disclaimer: boolean;
  requestorEmail: string;
  requestorConfirmEmail?: string;
  requestorContactMailType: string;
  requestorIdType: string;
  requestorIdValue: string;
  customerFirstName: string;
  customerMiddleName?: string;
  customerLastName: string;
  customerPhoneType: string;
  customerPhoneValue: any;
  customerAddress: string;
  customerCity: string;
  customerState: string;
  customerZip: string;
  customerEmail: string;
  customerConfirmEmail?: string;
  sameAsRequestor?: string;
};

export const consumerIDType = [
  { value: t('SOCIAL_SECURITY_NUMBER'), label: t('SOCIAL_SECURITY_NUMBER') },
  {
    value: `${t('DRIVERS_LICENSE')} / ${t('STATE_ID_CARD')}`,
    label: `${t('DRIVERS_LICENSE')} / ${t('STATE_ID_CARD')}`,
  },
  { value: t('MATRICULA_ID'), label: t('MATRICULA_ID') },
  { value: t('PASSPORT'), label: t('PASSPORT') },
  { value: t('RESIDENT_ALIEN'), label: t('RESIDENT_ALIEN') },
  { value: t('VISA_ID'), label: t('VISA_ID') },
  { value: t('MILITARY_ID'), label: t('MILITARY_ID') },
  { value: t('OR_TRIBAL_ID'), label: t('OR_TRIBAL_ID') },
];

export const phoneType = [
  { value: t('HOME_PHONE'), label: t('HOME_PHONE') },
  { value: t('OFFICE_PHONE'), label: t('OFFICE_PHONE') },
  { value: t('MOB_PHONE'), label: t('MOB_PHONE') },
];

function validateDisclaimer(value: boolean, context: AdditionalContext) {
  if (!value) {
    return t('ERROR_DISCLAIMER');
  }
  return null;
}

function validatePhoneType(value: any, context: AdditionalContext) {
  if (!value) {
    return `${t('ENTER')} ${t('CONTACT_PHONE_TYPE').toLowerCase()}`;
  }
  return null;
}

function validateIDType(value: any, context: AdditionalContext) {
  if (!value) {
    return t('PLEASE_SELECT_ID_TYPE');
  }
  return null;
}

function validateIDValue(value: string, context: AdditionalContext) {
  if (!(value.length === 4)) {
    return t('ENTER_LAST_FOUR_CHARACTERS_OF_YOUR_ID');
  }
  return null;
}

type AdditionalContext = {
  t: UseTranslationHook['t'];
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  return {
    requestorFirstName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('FIRST_NAME'),
    ),
    requestorMiddleName: validateAlphabetCharacters.bind(
      null,
      t('MIDDLE_NAME'),
    ),
    requestorLastName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('LAST_NAME'),
    ),
    requestorEmail: validateEmail,
    requestorPhoneType: validatePhoneType,
    requestorPhoneValue: validatePhoneNumber,
    disclaimer: validateDisclaimer,
    requestorIdType: validateIDType,
    requestorIdValue: validateIDValue,
    requestorConfirmEmail: value => {
      if (!value?.length) {
        return t('BLANK_EMAIL');
      }
      if (value !== context.values.requestorEmail) {
        return t('ERROR_EMAILS_DO_NOT_MATCH');
      }
      return null;
    },
    requestorAddress: validateAddressField,
    requestorCity: validateExistsAndAlphabetCharacters.bind(null, t('CITY')),
    requestorState: validateNameField.bind(null, t('STATE')),
    requestorZip: validateZipCode,
    customerFirstName:
      context.values.sameAsRequestor === 'someone'
        ? validateExistsAndAlphabetCharacters.bind(null, t('FIRST_NAME'))
        : null,
    customerMiddleName:
      context.values.sameAsRequestor === 'someone'
        ? validateAlphabetCharacters.bind(null, t('MIDDLE_NAME'))
        : null,
    customerLastName:
      context.values.sameAsRequestor === 'someone'
        ? validateExistsAndAlphabetCharacters.bind(null, t('LAST_NAME'))
        : null,
    customerEmail:
      context.values.sameAsRequestor === 'someone' ? validateEmail : null,
    customerPhoneType:
      context.values.sameAsRequestor === 'someone' ? validatePhoneType : null,
    customerPhoneValue:
      context.values.sameAsRequestor === 'someone' ? validatePhoneNumber : null,
    customerConfirmEmail: value => {
      if (context.values.sameAsRequestor === 'someone' && !value?.length) {
        return t('BLANK_EMAIL');
      }
      if (
        context.values.sameAsRequestor === 'someone' &&
        value !== context.values.customerEmail
      ) {
        return t('ERROR_EMAILS_DO_NOT_MATCH');
      }
      return null;
    },
    customerAddress:
      context.values.sameAsRequestor === 'someone'
        ? validateAddressField
        : null,
    customerCity:
      context.values.sameAsRequestor === 'someone'
        ? validateExistsAndAlphabetCharacters.bind(null, t('CITY'))
        : null,
    customerState:
      context.values.sameAsRequestor === 'someone'
        ? validateNameField.bind(null, t('STATE'))
        : null,
    customerZip:
      context.values.sameAsRequestor === 'someone' ? validateZipCode : null,
  };
});
