import { useReducer } from 'react';
import { AccountSort } from '../../../__generated__/pge-types';
import { PageSize } from '../../pagination/types';
import AccountListReducer from './accountListReducer';
import useFeatureFlagProtectedValues from './useFeatureFlagProtectedValues';
export default () => {
  const {
    getAccountListStateByClosedAcctFlag,
  } = useFeatureFlagProtectedValues();
  const [accountListParams, dispatch] = useReducer(
    AccountListReducer.reducer,
    getAccountListStateByClosedAcctFlag(),
  );

  const onAccountSearch = (search: string) => {
    dispatch(AccountListReducer.actions.setQuery(search));
  };

  const onAccountSort = (value: AccountSort) =>
    dispatch(AccountListReducer.actions.setSortBy(value));

  const onAccountPageChange = (newPage: number, newPageSize: PageSize) => {
    if (newPage !== accountListParams.page) {
      dispatch(AccountListReducer.actions.setPage(newPage));
    }
    if (newPageSize !== accountListParams.pageSize) {
      dispatch(AccountListReducer.actions.setPageSize(newPageSize));
    }
  };

  const onResetParams = () => {
    dispatch(AccountListReducer.actions.resetParams());
  };

  return {
    accountListParams,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
    onResetParams,
  };
};
