import React from 'react';
import MaskedInput from 'react-text-mask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, inputProps, ...other } = textMaskProps;

  return (
    <MaskedInput
      data-testid={'zipInputField'}
      {...other}
      name={textMaskProps.name || 'zipCode'}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-zA-Z]/,
        /[0-9]/,
        /[a-zA-Z]/,
        ' ',
        /[0-9]/,
        /[a-zA-Z]/,
        /[0-9]/,
      ]}
      guide={false}
      showMask
    />
  );
};

const ZipInputField = React.forwardRef(
  (props: TextFieldProps, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();

    return (
      <TextField
        label={props.label || t('ZIP_CODE')}
        {...props}
        ref={ref}
        name={props.name || 'zip'}
        helperText={props.helperText || props.error}
        defaultValue={props.defaultValue || null}
        style={props.style || { width: '100%' }}
        InputProps={{
          ...props.InputProps,
          inputComponent: TextMaskCustom,
        }}
        inputProps={{
          ...props.inputProps,
          type: 'tel', // Use tel instead of number to avoid browser behaviour
        }}
      />
    );
  },
);
export default ZipInputField;
