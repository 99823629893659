import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, inputProps, ...other } = textMaskProps;
  const numberMask = createNumberMask({
    prefix: '',
    integerLimit: 5,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  });

  const fullZipCodeMask =
    textMaskProps?.zipValue?.length > 5
      ? [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      : createNumberMask({
          prefix: '',
          includeThousandsSeparator: false,
          allowLeadingZeroes: true,
        });

  return (
    <MaskedInput
      data-testid={'zipInputField'}
      {...other}
      name={textMaskProps.name || 'zipCode'}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={textMaskProps?.showFullZipCode ? fullZipCodeMask : numberMask}
      guide={false}
      showMask
    />
  );
};

const ZipInputField = React.forwardRef(
  (props: TextFieldProps, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();

    return (
      <TextField
        label={props.label || t('ZIP_CODE')}
        {...props}
        ref={ref}
        name={props.name || 'zip'}
        helperText={props.helperText || props.error}
        defaultValue={props.defaultValue || null}
        style={props.style || { width: '100%' }}
        InputProps={{
          ...props.InputProps,
          inputComponent: TextMaskCustom,
        }}
        inputProps={{
          ...props.inputProps,
          zipValue: props.value,
          type: 'tel', // Use tel instead of number to avoid browser behaviour
          inputMode: 'numeric',
        }}
      />
    );
  },
);
export default ZipInputField;
