import { useContext } from 'react';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import AccountListReducer, { AccountListState } from './accountListReducer';

export default () => {
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const getAccountListStateByClosedAcctFlag = (): AccountListState => {
    return {
      ...AccountListReducer.initialState,
      accountStatus: isClosedAccountsEnabled ? 'ACTIVE' : 'ALL',
    };
  };

  return {
    getAccountListStateByClosedAcctFlag,
  };
};
